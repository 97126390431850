import React from 'react';

import Button from 'common-ui-components/Button';

import { ReactComponent as MagnifyingGlassIcon } from 'assets/img/icon/magnifying-glass-icon.svg';

import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/OriginalQuestion/style.module.scss';

interface Props {
  handleClick: () => void;
  searchQuery: string;
}

export default function OriginalQuestion({ handleClick, searchQuery }: Props) {
  return (
    <div className={style.originalQuestion}>
      <MagnifyingGlassIcon className={style.magnifyingGlassIcon} />
      <Button
        onClick={handleClick}
        transparent
        className={style.text}
      >
        {searchQuery}
      </Button>
    </div>
  );
}
