import React from 'react';

import TagBadge from 'common-ui-components/Badge/TagBadge';
import Button from 'common-ui-components/Button';
import { Heading3 } from 'common-ui-components/Typograhpy';
import OrganizationResult from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/OrganizationResult';
import Size from 'global/lists/Size';
import { useInternalScreenRedirect } from 'global/ScreensConfiguration';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import PersonBadge from 'screens/platform/cross-platform-components/PersonBadge';
import DebuggerConsole from 'utils/DebuggerConsole';

import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/style.module.scss';

interface Props {
  entity: 'topics' | 'organizations' | 'people';
  options: string[];
  optionsCount: number;
  selectValue: (value: string) => void;
}

export default function AutocompleteResultCategory({
  entity,
  options,
  optionsCount,
  selectValue,
}: Props) {
  const redirectToInternalScreen = useInternalScreenRedirect();
  const { getTagColors } = useMetadataContext();

  const renderBadge = (option: string) => {
    switch (entity) {
      case 'topics':
        return (
          <TagBadge
            tag={option}
            colors={getTagColors(option)}
            clickable={false}
          />
        );
      case 'organizations':
        return (
          <OrganizationResult
            organizationId={option}
          />
        );
      case 'people':
        return (
          <PersonBadge
            personId={option}
            hasDepartment
            hasBorder={false}
            size={Size.MEDIUM}
            clickable={false}
            onLiner
          />
        );
      default:
        DebuggerConsole.error('Failed to retrieve information for entity', entity);
        return <span>{option}</span>;
    }
  };

  if (optionsCount === 0) return null;

  const title = `${entity} (${optionsCount})`;

  return (
    <div className={style.autoCompleteResultCategory}>
      <Heading3 className={style.title}>{title}</Heading3>
      {options.map((option) => (
        <Button
          key={option}
          className={style.option}
          onClick={() => {
            redirectToInternalScreen(
              `platform.directory.${entity}.profile`,
              option,
            );
            selectValue(option);
          }}
        >
          {renderBadge(option)}
        </Button>
      ))}
    </div>
  );
}
