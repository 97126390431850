enum ConfigurationKey {
  REPORTS_URL = 'reports.url',
  IS_SOA_THRESHOLD_BY_ORGANIZATIONS_OBJECTIVE_ACTIVATED = 'is.soa.by.organizations.objective.activated',
  IS_FOLLOW_INTEREST_FEATURE_ACTIVATED = 'is.follow.interest.feature.activated',
  IS_ORGANIZATIONS_FEATURE_ACTIVATED = 'akooda.datalib.is.organizations.feature.activated',
  MAX_DISPLAY_TIME_PERIOD_IN_DAYS = 'akooda.datalib.max.display.time.period.in.days',
  IS_TIME_IN_MEETINGS_FOR_LONG_PERIODS_METRICS_ENABLED = 'is.time.in.meetings.for.long.periods.metrics.enabled',
  MAX_ATOMS_COUNT = 'akooda.clientapi.max.atom.count',
  IS_AKOODA_AI_FEATURE_ENABLED = 'akooda.datalib.is.akooda.ai.feature.enabled',
  IS_COMPANY_EFFORTS_ENABLED = 'is.company.efforts.enabled',
  SHOW_SUGGESTED_TAGS_KEYWORDS_AS_KEYWORD_OPTIONS = 'show.suggested.tags.keywords.as.keyword.options',
  IS_ENTERPRISE_SEARCH_ENABLED = 'akooda.datalib.is.enterprise.search.enabled',
  IS_SEARCH_RESULTS_IN_ENTERPRISE_SEARCH_ENABLED = 'is.search.results.in.enterprise.search.enabled',
  IS_EXTENSION_ENABLED = 'is.extension.enabled',
  IS_SALES_DEMO_MODE = 'is.sales.demo.mode',
  IS_PREPROCESSING_ENABLED = 'akooda.integrationlib.is.preprocessing.enabled',
  IS_AUTOCOMPLETE_ENABLED = 'is.autocomplete.enabled'
}

export default ConfigurationKey;
