import React from 'react';

import OrganizationProfileImage from 'common-ui-components/MultiImages/ProfileImage/OrganizationProfileImage';
import Spinner from 'common-ui-components/Spinner';
import Size from 'global/lists/Size';
import useAsync from 'react-use/lib/useAsync';
import { useMetadataContext } from 'screens/platform/cross-platform-components/context/metadata/MetadataContext';
import { getOrganizationName } from 'utils/OrganizationUtils';

import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/OrganizationResult/style.module.scss';

interface Props {
  organizationId: string;
}

export default function OrganizationResult({ organizationId }: Props) {
  const { organizations } = useMetadataContext();

  const { value: organization, loading } = useAsync(
    () => organizations.getById(organizationId),
    [organizationId],
  );

  if (loading) return <Spinner />;

  if (!organization) return null;

  return (
    <div className={style.organizationAutocompleteResult}>
      <OrganizationProfileImage organization={organization} size={Size.MEDIUM} />
      <div>{getOrganizationName(organization)}</div>
    </div>
  );
}
