import React, { Dispatch, useEffect, useRef } from 'react';

import Dropdown from 'common-ui-components/Dropdown';
import useKeyboardNavigation from 'common-ui-components/Dropdown/keyboardNavigationHook';
import Spinner from 'common-ui-components/Spinner';
import SearchInput from 'es-src/screens/HomeScreen/components/EnterpriseSearch/SearchInput';
import AutocompleteResultCategory from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory';
import OriginalQuestion from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/OriginalQuestion';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import { AutoCompleteResponse } from 'global/api/controller/EnterpriseSearchController';
import Api from 'global/api/platformApi';
import HttpStatus from 'global/lists/HttpStatus';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useDebounce, useMountedState } from 'utils/hooks';

import autoCompleteStyle from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/AutocompleteResultCategory/style.module.scss';
import style from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete/style.module.scss';

interface Props {
  searchQuery: string;
  setSearchQuery: Dispatch<React.SetStateAction<string>>;
  placeholder: string;
}

const MIN_AUTOCOMPLETE_CHARS = 3;

export default function SearchWithAutocomplete({
  searchQuery,
  setSearchQuery,
  placeholder,
}: Props) {
  const { tenant } = useTenantContext();
  const [options, setOptions] = useMountedState<AutoCompleteResponse | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useMountedState(false);
  const [isLoading, setIsLoading] = useMountedState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const {
    loading: isEnterpriseSearchLoading,
    handleEnterpriseSearch,
    originalQuestion,
  } = useEnterpriseSearchContext();

  const debouncedQuery = useDebounce(searchQuery);

  useKeyboardNavigation({
    isDropdownOpen,
    menuElement: containerRef,
    closeDropdown: () => setIsDropdownOpen(false),
    selectedOptionClassName: autoCompleteStyle.highlightedOption,
    inputRef,
    automaticallySelectFirstOption: true,
  });

  useEffect(() => {
    const handleAutoComplete = async () => {
      if (debouncedQuery.length < MIN_AUTOCOMPLETE_CHARS
        || isEnterpriseSearchLoading || debouncedQuery === originalQuestion) {
        setIsDropdownOpen(false);
        setOptions(null);
        return;
      }

      try {
        setIsLoading(true);
        setOptions(null);

        const searchResponse = await Api.EnterpriseSearch.getAutoComplete(tenant.id, searchQuery);

        if (searchResponse?.status === HttpStatus.OK) {
          setOptions(searchResponse.data);
          const hasResults = [
            searchResponse.data.people?.totalResultsCount,
            searchResponse.data.topics.totalResultsCount,
            searchResponse.data.organizations.totalResultsCount,
          ].some((count) => count > 0);
          setIsDropdownOpen(hasResults);
        }
      } catch (err) {
        DebuggerConsole.error('Failed to fetch autocomplete', err);
        setOptions(null);
        setIsDropdownOpen(false);
      } finally {
        setIsLoading(false);
      }
    };

    handleAutoComplete();
  }, [debouncedQuery, isEnterpriseSearchLoading, tenant.id]);

  return (
    <Dropdown
      isDropdownOpen={isDropdownOpen}
      closeDropdown={() => setIsDropdownOpen(false)}
      placement="bottom-start"
      dropdownClassName={style.dropdownClassName}
      toggleButtonElement={(
        <SearchInput
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          placeholder={placeholder}
          autofocus
          customInputRef={inputRef}
        />
      )}
    >
      <div ref={containerRef}>
        {isLoading && (
          <div className="spinner">
            <Spinner />
          </div>
        )}
        {!isLoading && options && (
          <>

            <OriginalQuestion
              searchQuery={searchQuery}
              handleClick={() => handleEnterpriseSearch(searchQuery, 'originalQuestion', true)}
            />

            <div className={style.separator} />

            <AutocompleteResultCategory
              entity="topics"
              options={options.topics.results.map((topic) => topic.value)}
              optionsCount={options.topics.totalResultsCount}
              selectValue={setSearchQuery}
            />

            {(options.topics.totalResultsCount > 0
              && (options.organizations.totalResultsCount > 0
                || options.people.totalResultsCount > 0)) && (
                <div className={style.separator} />
            )}

            <AutocompleteResultCategory
              entity="organizations"
              options={options.organizations.results.map((organization) => organization.id)}
              optionsCount={options.organizations.totalResultsCount}
              selectValue={setSearchQuery}
            />

            {options.organizations.totalResultsCount > 0
              && options.people.totalResultsCount > 0 && (
                <div className={style.separator} />
            )}

            <AutocompleteResultCategory
              entity="people"
              options={options.people.results.map((person) => person._id)}
              optionsCount={options.people.totalResultsCount}
              selectValue={setSearchQuery}
            />
          </>
        )}
      </div>
    </Dropdown>
  );
}
