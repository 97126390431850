import React, { useState } from 'react';

import CleanPromptSuggestions from 'es-src/screens/HomeScreen/components/CleanPromptSuggestions';
import SearchInput from 'es-src/screens/HomeScreen/components/EnterpriseSearch/SearchInput';
import Highlights from 'es-src/screens/HomeScreen/components/Highlights';
import SearchWithAutocomplete from 'es-src/screens/HomeScreen/components/SearchWithAutocomplete';
import SuggestedQuestions from 'es-src/screens/HomeScreen/components/SuggestedQuestions';
import { useEnterpriseSearchContext } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnterpriseSearchContext';
import ConfigurationKey from 'global/lists/ConfigurationKey';
import { useConfiguration } from 'screens/platform/cross-platform-components/context/configuration/ConfigHook';

import style from 'es-src/screens/HomeScreen/components/EnterpriseSearch/style.module.scss';

export default function EnterpriseSearch() {
  const {
    loading,
    isResultCardOpen,
    hasError,
  } = useEnterpriseSearchContext();
  const [searchQuery, setSearchQuery] = useState('');
  const shouldRenderSuggestedQuestions = !isResultCardOpen && !loading && hasError === null;
  const { value: isAutocompleteEnabled } = useConfiguration(
    ConfigurationKey.IS_AUTOCOMPLETE_ENABLED,
    'boolean',
  );

  return (
    <div className={style.enterpriseSearch}>
      <div>
        {isAutocompleteEnabled
          ? (
            <SearchWithAutocomplete
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Ask anything"
            />
          )
          : (
            <SearchInput
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              placeholder="Ask anything"
              autofocus
            />
          )}
        <CleanPromptSuggestions />
      </div>
      { shouldRenderSuggestedQuestions && <SuggestedQuestions /> }
      <Highlights />
    </div>
  );
}
